<template>
  <div class="custom-select" :tabindex="tabindex" @blur="blur" ref="selectRef">
    <div
      class="selected"
      :class="{ open: open }"
      @click="click"
      v-html="currentTitle"
    />
    <div class="items" :class="{ selectHide: !open }">
      <div v-for="(option, i) of options" :key="i" @click="select(option)">
        {{ option.title || option }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  reactive,
  SetupContext,
  toRefs,
  ref,
  computed,
  watch,
  onMounted,
} from 'vue';
import { SelectOption } from '@/types/Input';
export default defineComponent({
  props: {
    modelValue: [String, Number],
    options: {
      type: Array as PropType<SelectOption[]>,
      default: () => [] as SelectOption[],
    },
    defaultValue: {
      type: String,
      default: '',
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: ['update:modelValue', 'change'],
  setup: (props, { emit }: SetupContext) => {
    const data: any = reactive({
      open: false,
    });
    const selectRef = ref<any>(null);
    const currentOption = ref<any>(null);
    const { modelValue, options, defaultValue } = toRefs(props);
    watch(
      () => [modelValue, currentOption, selectRef.value],
      () => {
        currentOption.value = options.value?.find(
          (option: any) => option.value === modelValue || option === modelValue,
        );
        currentOption.value && emit('update:modelValue', currentValue);
        selectRef.value.addEventListener('keydown', keydownEvent);
      },
    );
    const currentValue = computed(() =>
      currentOption.value
        ? currentOption.value?.value !== undefined &&
          currentOption.value?.value !== null
          ? currentOption.value.value
          : currentOption.value
        : '&nbsp;',
    );
    const currentTitle = computed(() =>
      currentOption.value &&
      currentOption.value.title !== undefined &&
      currentOption.value.title !== null
        ? currentOption.value.title
        : defaultValue.value
        ? defaultValue.value
        : '&nbsp;',
    );
    const optionIndex = computed(() =>
      options.value.findIndex(
        (option: SelectOption) => option === currentOption.value,
      ),
    );
    const prevOption = computed(() => {
      if (optionIndex.value <= -1) {
        return options.value[options.value.length - 1];
      }
      return optionIndex.value >= 1
        ? options.value[optionIndex.value - 1]
        : options.value[options.value.length - 1];
    });
    const nextOption = computed(() => {
      if (optionIndex.value <= -1) {
        return options.value[0];
      }
      return optionIndex.value < options.value.length - 1
        ? options.value[optionIndex.value + 1]
        : options.value[0];
    });
    const keydownEvent: any = (event: any): void => {
      switch (event) {
        case 'ArrowDown':
          event.preventDefault();
          currentOption.value = nextOption.value;
          data.open = true;
          break;
        case 'ArrowUp':
          event.preventDefault();
          currentOption.value = prevOption.value;
          data.open = true;
          break;
        case 'Escape':
          event.preventDefault();
          data.open = false;
          break;
      }
    };

    onMounted(() => {
      currentOption.value = options.value?.find(
        (option: any) => option.value === modelValue || option === modelValue,
      );

      window.document.addEventListener('keydown', keydownEvent);
    });

    const blur = (): boolean => (data.open = false);
    const click = (): boolean => (data.open = !data.open);
    const select = (option: any): void => {
      data.open = false;
      currentOption.value = option;
      emit('update:modelValue', currentValue.value);
    };
    return { ...toRefs(data), blur, click, select, currentTitle, selectRef };
  },
});
</script>
